<template>
  <div class="client__edit">
    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      @validate="onValidate"
    >
      <template #default="{ errors, action }">
        <div class="client__edit-item">
          <AppText variant="div" mb="8px">
            {{ t('common.name') }}
          </AppText>

          <FInput
            v-model="requestFields.fullName"
            has-prefix
            :maxlength="25"
            :validation-error="errors.fullName"
          >
            <template #prefix>
              <AppIcon
                name="user"
                :opacity="0.4"
                size="18px"
                is-img-tag
              />
            </template>
          </FInput>
        </div>

        <div class="client__edit-item">
          <AppText variant="div" mb="8px" mt="24px">
            {{ t('common.email') }}
          </AppText>

          <div class="client-email">
            <TooltipCopy
              class="flex-grow-1"
              is-icon-hidden
            >
              <AppIcon
                name="mail"
                opacity="0.4"
                size="18px"
              />
              <AppText
                variant="div"
                opacity="0.4"
                ml="12px"
              >
                {{ truncatedEmail }}
              </AppText>
            </TooltipCopy>
            <AppTooltip
              key="Email"
              width="252px"
              cursor="default"
            >
              <template #text>
                {{ t('merchantClients.clientInfoModal.label.cantBeEdited') }}
              </template>
              <AppIcon name="lock" size="18px" :opacity="0.4" is-img-tag />
            </AppTooltip>
          </div>
        </div>
        <div class="client__edit-buttons">
          <FButton
            :disabled="isDisabledSave"
            no-margin
            @click="action"
          >
            {{ t('merchantClients.clientInfoModal.button.save') }}
          </FButton>
          <FButton
            type="plain"
            no-margin
            @click="closeModal"
          >
            {{ t('common.cancel') }}
          </FButton>
        </div>
      </template>
    </ActionForm>
  </div>
</template>

<script>
import { computed, watch } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { ActionForm, fieldValidation, nameRule } from '@/validation';
import { useModel } from '@/composables/useModel';
import { truncateLongString } from '@/utils/functions';

export default {
  name: 'ClientEdit',
  components: {
    ActionForm,
    AppTooltip,
    TooltipCopy,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    isDisabledSave: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update'],
  setup(props, { emit }) {
    const { value: isOpenProp } = useModel(props, emit, { prop: 'isOpen' });

    const closeModal = () => {
      emit('close');
    };

    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      hasDataBeenChanged,
    } = fieldValidation({ fieldName: 'fullName', rule: nameRule });

    watch(computed(() => props.client.clientName), (fullName) => {
      requestFields.fullName = fullName;
    }, { immediate: true });

    const onValidate = () => {
      if (hasDataBeenChanged.value) {
        emit('update', requestFields.fullName);
      }
      emit('close');
    };

    const truncatedEmail = computed(() => truncateLongString(props.client.clientEmail));

    return {
      isOpenProp,
      truncatedEmail,
      closeModal,

      validationSchema,
      requestFields,
      fieldsErrors,
      onValidate,
    };
  },
};
</script>

<style lang="scss" scoped>
.client__edit {
  .client__edit-item {
    display: flex;
    flex-direction: column;
    &:first-child {
      margin-top: 0;
    }
  }
  .client-email{
    height: 44px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 12px;
    background: #F2F2F2;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
  }
  &-buttons {
    margin-top: 20px;
    display: flex;
    & > * {
      width: 50%;
    }
  }
}
</style>
