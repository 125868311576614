<template>
  <div class="d-flex align-items-center">
    <TooltipCopy is-icon-hidden>
      <AppText opacity="0.5">
        {{ clientId }}
      </AppText>
    </TooltipCopy>
    <DotSeparator color="#ccc" margin="0 12px" />
    <AppTooltip text-align="left" width="312px" cursor="default">
      <template #text>
        <template v-if="status === 'OPEN'">
          {{ t('merchantClients.clientInfoModal.label.openStatusTooltip') }}
        </template>
        <template v-else>
          {{ t('merchantClients.clientInfoModal.label.dndStatusTooltip') }}
        </template>
      </template>

      <div class="d-flex align-items-center">
        <AppText
          :color="isBlocked ? 'var(--color-primary)' : 'var(--color-green)'"
          class="font-medium"
          :class="{ inactive: isBlocked }"
        >
          {{ t(STATUS[status]) }}
        </AppText>
        <AppIcon
          class="status-icon"
          style="margin-left: 8px; transform: rotate(180deg);"
          :class="{ open: !isBlocked }"
          name="warning-alert-attention"
          size="14px"
        />
      </div>
    </AppTooltip>

    <SmallActionButton @action="$emit('update')" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';
import SmallActionButton from '@/components/Buttons/SmallActionButton.vue';
import AppTooltip from '@/components/AppTooltip.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { STATUS } from '@/common/status';

export default defineComponent({
  components: {
    DotSeparator,
    TooltipCopy,
    AppTooltip,
    SmallActionButton,
  },
  props: {
    clientId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    isBlocked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
  setup() {
    return {
      STATUS,
    };
  },
});
</script>

<style scoped lang="scss">
.status-icon.open {
  :deep(circle) {
    fill: var(--color-green) !important;
  }
}

:deep(.refresh) {
  margin-left: 20px;
}
</style>
