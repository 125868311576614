<template>
  <div
    :ref="el => { if (el) itemContent = el }"
    v-click-outside="isEditable ? onOutsideClick : () => {}"
    class="table__row__cell-wrap"
    :class="{
      'is-cell-editable': column.isEditable && !row.isDuplicated,
      'is-cell-editable-focused': isEditing,
      'is-cell-duplicated': row.isDuplicated && column.name === 'email',
    }"
    @click="onEdit"
  >
    <template v-if="column.icon">
      <AppIcon
        v-if="row[column.name]?.icon"
        :name="row[column.name]?.icon"
        class="table__row__cell-icon"
      />
      <span
        v-else
        :style="{ marginRight: `${column.icon + 16}px` }"
      />
    </template>
    <slot
      name="cell"
      :row="row"
      :item="row[column.name]?.cellValue || row[column.name]"
      :i="i"
      :column="column"
    />
    <FInput
      v-if="isEditing"
      ref="editableInput"
      v-model="newValue"
      class="editable-cell"
      @keypress.enter.stop="onEditEnd"
    />
    <AppIcon
      v-if="column.copy"
      class="table__row__cell-copy"
      name="copy"
      size="18px"
      @click="copyCell({ column, item })"
    />
  </div>
</template>

<script>
import {
  defineComponent, onMounted, ref,
} from 'vue';

import clickOutside from '@/directives/clickOutside';

export default defineComponent({
  directives: {
    clickOutside,
  },
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: [String, Number, Object],
      default: '',
    },
    modelValue: {
      type: [String, Number, Object],
      default: '',
    },
    i: {
      type: [String, Number, Object],
      default: '',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'edit-complete'],
  setup(props, { emit }) {
    const isEditing = ref(false);
    const editableInput = ref(null);

    const newValue = ref(props.modelValue);

    const onEdit = () => {
      if (props.column.isEditable && !props.row.isDuplicated) {
        isEditing.value = true;
        setTimeout(() => {
          editableInput.value.$el.querySelector('.el-input__inner').focus();
        }, 0);
      }
    };

    const onEditEnd = () => {
      isEditing.value = false;
      if (props.modelValue !== newValue.value && props.column.isEditable) {
        emit('update:modelValue', newValue.value);
        emit('edit-complete');
      }
    };

    const onOutsideClick = () => {
      onEditEnd();
    };

    const returnedObject = {
      isEditing,
      newValue,
      editableInput,

      onEdit,
      onEditEnd,
      onOutsideClick,
    };

    if (props.column.width) {
      const itemContent = ref(null);
      const isTruncated = ref(false);

      onMounted(() => {
        const el = itemContent.value;
        isTruncated.value = el.scrollWidth > el.clientWidth;
      });

      return {
        itemContent,
        isTruncated,
        ...returnedObject,
      };
    }

    return {
      isTruncated: false,
      ...returnedObject,
    };
  },
});
</script>

<style scoped lang="scss">
.table__row__cell-wrap {
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.editable-cell {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px !important;

  :deep(.el-input__wrapper) {
    min-height: 35px !important;
    height: 35px !important;
    border: unset !important;
  }
  :deep(.el-input__inner) {
    min-height: 35px !important;
    height: 35px !important;
    border: unset;
    padding-left: 8px !important;
    position: relative;
    top: -1px;
  }
}

</style>
