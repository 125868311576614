<template>
  <div
    class="table__wrap"
    :class="{
      'has-hovered-row': !disableHover,
      'has-no-border': disableBorder,
    }"
  >
    <CustomScroll>
      <table ref="table" class="table">
        <div
          class="table__head"
          :class="{
            'is-data-loading': isLoading,
          }"
        >
          <thead class="table__row">
            <th
              v-for="(item) in columns"
              :key="item.id"
              class="table__head__column table__column"
              :style="{
                '--width': item.width ? `${item.width}px` : 'unset',
              }"
            >
              <div class="table__column__wrap d-flex ">
                <div class="table__column__cell">
                  <slot name="column" :column="item">
                    <AppText type="uppercase">
                      {{ item.title }}
                    </AppText>
                  </slot>
                </div>
              </div>
            </th>
          </thead>
          <LinearProgress :is-loading="isLoading" />
        </div>
        <tbody class="table__body">
          <template v-if="data.length">
            <slot name="top-row" />
            <template v-for="(item, i) in data" :key="item.id">
              <ContainerWrapper
                class="table__row"
                :class="{
                  'is-row-duplicated': Boolean(item.isDuplicated),
                }"
              >
                <AppTooltip
                  :is-enabled="Boolean(item.isDuplicated)"
                  style="display: contents;"
                  text-align="left"
                  :width="item.isResponseDuplicated ? '260px' : 'auto'"
                >
                  <template #text>
                    {{ item.isResponseDuplicated ? t('merchantClients.clientCreateModal.serverError.clientIsExists') : t('merchantClients.clientCreateModal.serverError.duplicated') }}
                  </template>
                  <div
                    v-for="col in columns"
                    :key="col.id"
                    class="table__row__cell table__column"
                    :class="{
                      [`table__column-${col.name}`]: col.name,
                    }"
                    :style="{
                      '--width': col.width ? `${col.width}px` : 'unset',
                      '--overflow-cell': (col.width && !col.type) ? 'hidden' : 'unset',
                    }"
                  >
                    <TableCell
                      v-model="item[col.name]"
                      :row="item"
                      :column="col"
                      :item="(item[col.name]?.cellValue || item[col.name])"
                      :is-editable="isEditable"
                      @edit-complete="$emit('edit-complete')"
                    >
                      <template #cell>
                        <slot
                          name="cell"
                          :row="item"
                          :item="(item[col.name]?.cellValue || item[col.name])"
                          :i="i"
                          :column="col"
                        />
                      </template>
                    </TableCell>
                  </div>
                </AppTooltip>
              </ContainerWrapper>
            </template>
          </template>
          <template v-else>
            <slot name="nodata">
              <NoData :is-loading="isLoading" :colspan="20" />
            </slot>
          </template>
          <slot name="append-rows" />
          <div
            v-if="isShowAppendedRow"
            class="table__row table__row__appended"
          >
            <div
              v-for="col in columns"
              :key="col.id"
              class="table__row__cell table__column"
            />
          </div>
        </tbody>
      </table>
    </CustomScroll>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import ContainerWrapper from '@/components/Containers/ContainerWrapper.vue';
import LinearProgress from '@/components/Progress/LinearProgress.vue';
import TableCell from '@/components/MainTable/TableCell.vue';
import AppTooltip from '@/components/AppTooltip.vue';

import NoData from './NoData.vue';

export default defineComponent({
  name: 'MainTable',
  components: {
    TableCell,
    AppTooltip,
    ContainerWrapper,
    LinearProgress,
    NoData,
  },
  props: {
    columns: { type: Array, default: () => [] },
    data: { type: Array, default: () => [] },
    fullHeight: { type: Boolean, default: false },
    disableBorder: { type: Boolean, default: false },
    disableHover: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: false },
  },
  emits: ['edit-complete'],
  setup(props) {
    const add = ref(false);
    const table = ref(null);

    const isShowAppendedRow = computed(() => props.fullHeight);

    return {
      add,
      table,
      isShowAppendedRow,
    };
  },
});
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
@import './table';
@import './table-editable';
</style>
