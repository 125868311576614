<template>
  <SmoothModal
    :model-value="isModalOpen"
    :border-top-color="isClientBlocked ? 'var(--color-primary)' : null"
    :has-header-divider="false"
    width="480px"
  >
    <template #title>
      {{ t('merchantClients.clientInfoModal.title') }}
    </template>
    <template #description>
      <ClientDescription
        :client-id="String(client.clientId)"
        :status="client.status"
        :is-blocked="isClientBlocked"
        @update="onUpdateData"
      />
    </template>
    <div style="padding-top: 2px;">
      <AppText
        size="14px"
        variant="div"
        mb="7px"
        opacity="0.4"
      >
        {{ t('common.revenue') }}
      </AppText>
      <TooltipCopy is-icon-hidden>
        <CurrencyFormatter
          :value="client.revenue"
          is-account-currency
          class="font-medium"
          size="20px"
        />
      </TooltipCopy>

      <div class="data" :class="{ 'is-ru-lang': isRuLanguage }">
        <div class="data-item">
          <AppText
            size="14px"
            variant="div"
            mb="10px"
            opacity="0.4"
          >
            {{ t('common.payments') }}
          </AppText>
          <AppText
            size="14px"
            variant="div"
          >
            {{ client.paymentsCount ?? 0 }}
          </AppText>
        </div>
        <div class="data-item">
          <AppText
            size="14px"
            variant="div"
            mb="10px"
            opacity="0.4"
          >
            {{ t('merchantClients.clientInfoModal.label.lastPayment') }}
          </AppText>
          <DateFormatter
            v-if="client.lastPayment"
            :date="client.lastPayment"
            :format="dateFormat"
            size="14px"
          />
          <AppText
            v-else
            size="14px"
          >
            {{ t('common.never') }}
          </AppText>
        </div>
        <div class="data-item">
          <AppText
            size="14px"
            variant="div"
            mb="10px"
            opacity="0.4"
          >
            {{ t('common.registered') }}
          </AppText>
          <DateFormatter :date="client.createdAt" :format="dateFormat" size="14px" />
        </div>
      </div>

      <ClientEdit
        :client="client"
        :is-disabled-save="isDisabledSave"
        @close="isModalOpen = false"
        @update="onUpdateClient"
      />
    </div>
  </SmoothModal>
</template>

<script>
import { computed, ref } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import SmoothModal from '@/components/SmoothModal';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { useSettings } from '@/composables/useSettings';
import { useModel } from '@/composables/useModel';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

import { creationTypes } from '../data';
import { setClientName } from '../api';

import ClientEdit from './ClientEdit.vue';
import ClientDescription from './ClientDescription.vue';

export default {
  name: 'ClientModal',
  components: {
    DateFormatter,
    TooltipCopy,
    SmoothModal,
    ClientEdit,
    ClientDescription,
    CurrencyFormatter,
  },
  props: {
    client: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  emits: ['update-data'],
  setup(props, { emit }) {
    const { value: isModalOpen } = useModel(props, emit);
    const clientName = ref(props.client?.clientName);
    const isDisabledSave = ref(false);

    const onUpdateData = () => {
      emit('update-data');
    };

    const isClientBlocked = computed(() => props.client?.status === 'DO_NOT_DISTURB');

    const onUpdateClient = async (newName) => {
      const { isSuccess } = await setClientName({
        clientId: props.client.id,
        fullName: newName,
      });
      if (isSuccess) {
        onUpdateData();
      }
    };

    const { dateFormat, language } = useSettings();
    const isRuLanguage = language.value === 'ru';

    useKeyboardEvent(keys.BACKQUOTE, () => { isModalOpen.value = false; });

    return {
      isModalOpen,
      isClientBlocked,
      clientName,

      creationTypes,
      dateFormat,

      onUpdateClient,
      onUpdateData,
      isDisabledSave,
      isRuLanguage,
    };
  },
};
</script>

<style lang="scss" scoped>
.data {
  display: flex;
  margin: 30px 0;
  gap: 40px;
  .data-item {
    position: relative;
    flex: 1;
    &::after {
      content: '';
      height: 20px;
      position: absolute;
      top: 10px;
      right: -20px;
      width: 1px;
      background: #D9D9D9;
    }

    &:last-child {
      &::after {
        content: unset;
      }
    }
  }

  &.is-ru-lang {
    .data-item {
      &:nth-child(2) {
        flex: 3;
      }
    }
  }
}
</style>
